import styled from 'styled-components';
import {
  Text,
  TextProps,
  Button,
  ButtonProps,
  Popover,
  PopoverProps,
} from '@audi/audi-ui-react-v2';
import React from 'react';

export const StyledLink = styled.a`
  font-family: var(${(props) => props.theme.fontFamily});
  font-size: var(${(props) => props.theme.responsive.typography.order3.fontSize});
  line-height: var(${(props) => props.theme.responsive.typography.order3.lineHeight});

  text-decoration: none;
  border-bottom: 0;
  color: var(${(props): string => props.theme.colors.base.brand.black});

  &:hover {
    color: var(${(props): string => props.theme.colors.base.grey[70]});
  }
`;

export const GridTileContainer = styled.div`
  position: relative;

  svg {
    transform: scale(1);
  }
`;

export const ListTileContainer = styled.div`
  position: relative;
  @media (max-width: 408px) {
    overflow: auto;
  }

  @media (min-width: 768px) {
    display: flex;
    flexflow: row;
  }

  svg {
    transform: scale(1);
  }
`;

export const GridGallery = styled.div``;

export const ListGallery = styled.div`
  margin-bottom: var(${(props): string => props.theme.responsive.spacing.xxl});
  @media (min-width: 768px) {
    flex: 0 0 40%;
    width: 40%;
    margin-bottom: 0;
  }
`;

export const ListContent = styled.div`
  padding: 0 var(${(props): string => props.theme.responsive.spacing.s});

  @media (min-width: 768px) {
    box-sizing: border-box;
    flex: 0 0 60%;
    width: 60%;
    padding-left: var(${(props): string => props.theme.responsive.spacing.xxl});
  }
`;

export const GridContent = styled.div`
  padding: 0 var(${(props): string => props.theme.responsive.spacing.s});
`;

export const GreyText = styled(Text)<TextProps>`
  color: var(${(props): string => props.theme.colors.base.grey[60]});
`;

// This can be replaced with <Badge variant="positive" /> after migrating to audi-ui-react-v2
export const Badge = styled.span`
  display: inline-block;
  margin-right: var(${(props): string => props.theme.responsive.spacing.xxs});
  width: 8px;
  height: 8px;
  line-height: 0;
  text-align: center;
  background-color: var(${(props) => props.theme.colors.ui.success});
  border-color: transparent;
  border-style: solid;
  border-width: 0px;
  border-radius: 100%;
`;

export const CompactWarrantyContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: var(${(props): string => props.theme.responsive.spacing.xl});
`;
export const PlusSealContainer = styled.div`
  position: relative;
`;

export const WarrantiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 561px) {
    flex-direction: row;
    width: fit-content;
  }
`;

export const WarrantyImgContainer = styled.div`
  &.dealer_warranty img {
    display: block;
    height: 35px;
    width: 95px;
    margin: 0 0 12px 12px;
  }
  &.gwplus5j img {
    display: block;
    height: 90px;
    width: 68px;
    margin: 0 0 12px 12px;
  }
  &.asg_extended img {
    display: block;
    height: 90px;
    width: 68px;
    margin: 0 0 12px 12px;
  }
`;
// Overriding tabs component innate margin to align it with other sections
export const TabsContainer = styled.div`
  margin-left: -12px;
`;
export const WarrantyInfoIconBtn = styled((props) => <Button {...props} />)<ButtonProps>`
  margin-left: var(${(props): string => props.theme.responsive.spacing.s});
  margin-bottom: var(${(props): string => props.theme.responsive.spacing.s});
`;
export const SealTextAndIconDiv = styled.div`
  display: inline-flex;
  align-items: center;
`;
export const StyledPopover = styled((props) => <Popover {...props} />)<PopoverProps>`
  width: 255px;
  z-index: 1 !important;
`;
export const PollutionBadge = styled.img`
  height: 28px;
  width: 28px;
`;
export const DistinctiveContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;
export const EnvkvValuesContainer = styled.div`
  margin-left: var(${(props): string => props.theme.responsive.spacing.s});
`;
