import { isAsset } from '@oneaudi/falcon-tools';
import { addSearchParams } from '@oneaudi/feature-app-utils';
export const urlSeparatorFor = (url) => {
    return url && url.indexOf('?') > -1 ? '&' : '?';
};
export const isMediaServiceImageUrl = (url) => {
    return url.indexOf('mediaservice') > -1;
};
export const isVtpImageUrl = (url) => {
    return url.indexOf('vtpimages') > -1;
};
export const vtpImageUrlAdaption = (url, width, height) => {
    if (isVtpImageUrl(url)) {
        if (url.indexOf('vtpimages.audi.de') > -1) {
            const parts = url.split('/');
            if (parts.length < 7) {
                return url;
            }
            if (width)
                parts[5] = String(width);
            if (height)
                parts[6] = String(height);
            return parts.join('/');
        }
        if (url.indexOf('vtpimages.audi.com') > -1 && width && height) {
            const urlParams = new Map();
            urlParams.set('width', width.toString());
            urlParams.set('height', height.toString());
            urlParams.set('fit', 'bounds');
            return addSearchParams(url.split('?')[0], urlParams);
        }
    }
    return url;
};
export function getImageUrl(url, width, height, mimetype) {
    if (isVtpImageUrl(url)) {
        return vtpImageUrlAdaption(url, width, height);
    }
    if (isMediaServiceImageUrl(url)) {
        const urlParams = new Map();
        if (width) {
            urlParams.set('wid', width.toString());
        }
        if (mimetype) {
            urlParams.set('mimetype', mimetype);
        }
        return addSearchParams(url, urlParams);
    }
    return url;
}
export function resolvePathFromContentReference(reference) {
    return isAsset(reference) ? reference.path : reference;
}
