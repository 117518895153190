import styled from 'styled-components';
import { Layout, LayoutProps } from '@audi/audi-ui-react-v2';

export const ListTilesContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: calc(2 * var(${(props): string => props.theme.responsive.spacing.xl}));
  margin-bottom: calc(2 * var(${(props): string => props.theme.responsive.spacing.xxxl}));

  @media screen and (min-width: 768px) {
    row-gap: calc(2 * var(${(props): string => props.theme.responsive.spacing.xl}));
  }

  @media screen and (min-width: 1024px) {
    row-gap: var(${(props): string => props.theme.responsive.spacing.xxl});
  }
`;

export const GridTilesContainer = styled.section`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: calc(2 * var(${(props): string => props.theme.responsive.spacing.xl}));
  column-gap: var(${(props): string => props.theme.responsive.spacing.l});
  margin-bottom: calc(2 * var(${(props): string => props.theme.responsive.spacing.xxxl}));

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: calc(2 * var(${(props): string => props.theme.responsive.spacing.xl}));
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: var(${(props): string => props.theme.responsive.spacing.xxl});
  }
`;

export const StyledButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const SecondRow = styled.div<{ isGridView?: boolean }>`
  display: flex;
  flex-direction: ${(props): string => (props.isGridView ? 'column' : 'row')};
  gap: 5px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  > * {
    flex: 1 0 auto;
  }
`;

// margin-left ≙ 24px svg icon + margin of svg icon
export const LayoutContainer = styled(Layout)<LayoutProps>`
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(
    var(${(props): string => props.theme.responsive.spacing.l}) +
      var(${(props): string => props.theme.responsive.spacing.s})
  );
`;

export const ScoreAndFavoritesContainer = styled.div`
  padding-block-end: var(${(props): string => props.theme.responsive.spacing.xxs});

  &:has(.matching-score-container) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: ${(props) => props.theme.breakpoints.m - 1}px) {
      padding-inline-start: var(${(props): string => props.theme.responsive.spacing.s});
    }
  }
`;
