import React from 'react';
import { useServicesContext, LayoutAdapter, useClientServerUtils } from '@oneaudi/vtp-shared';
import Results from './Results';
import Favorites from './Favorites';
import { trackFeatureAppReady } from './components/tracking';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { CombinedConfig } from './FeatureHubAppDefinition';
import { FavoritesSkeleton } from './components/Skeleton/FavoritesSkeleton';
import { ResultsSkeleton } from './components/Skeleton/ResultsSkeleton';
import Suggested from './Suggested';
import { ConfigurationServiceV1 } from '@oneaudi/vtp-configuration-service';
import { useSelector } from 'react-redux';
import { SELECTORS } from '@oneaudi/stck-store';

interface FeatureAppProps {}
const FeatureApp: React.FC<FeatureAppProps> = () => {
  const configurationTiles = useServicesContext().featureAppConfig as CombinedConfig;
  const configurationFA = useServicesContext().getAdditionalService(
    'vtp-configuration-service'
  ) as ConfigurationServiceV1;
  const isLoading = useSelector((state) => SELECTORS.UI.getLoadingState(state));
  const sortingConfiguration = configurationFA?.getConfiguration()?.sortParams;
  const enableMandatoryAreaSearch =
    configurationFA?.getConfiguration()?.enableMandatoryAreaSearch || false;
  const appContext = configurationTiles?.appContext || 'results';
  const viewType = configurationTiles?.viewType;
  /* eslint-disable no-nested-ternary */
  const sortParam = sortingConfiguration?.defaultOption
    ? sortingConfiguration?.defaultOption
    : sortingConfiguration?.options?.length
      ? sortingConfiguration?.options[0]
      : 'prices.retail:asc';
  /* eslint-disable no-nested-ternary */
  const { isClient } = useClientServerUtils();

  const showResults = isClient && !(enableMandatoryAreaSearch && isLoading);

  const trackingService = useServicesContext().getAdditionalService(
    'audi-tracking-service'
  ) as TrackingServiceV2;
  React.useEffect(() => {
    trackFeatureAppReady(trackingService, viewType!, sortParam, appContext);
  }, []);

  let component;
  let ssrComponent;
  let renderClient;
  switch (appContext) {
    case 'favorites':
      component = <Favorites />;
      ssrComponent = <FavoritesSkeleton />;
      renderClient = isClient && !isLoading;
      break;
    case 'results':
      component = showResults ? <Results /> : <ResultsSkeleton />;
      ssrComponent = <ResultsSkeleton />;
      renderClient = isClient && !isLoading;
      break;
    case 'suggested':
    default:
      component = <Suggested />;
      ssrComponent = <></>;
      renderClient = isClient;
      break;
  }

  return <LayoutAdapter>{renderClient ? component : ssrComponent}</LayoutAdapter>;
};

export default FeatureApp;
