import styled from 'styled-components';
import { SYS_BREAKPOINT_SM, SYS_BREAKPOINT_MD, SYS_BREAKPOINT_LG, SYS_BREAKPOINT_XL, } from '@oneaudi/unified-web-common';
export const OneCMSLayoutWrapper = styled.div `
  position: relative;
  margin: 0 16px;
  @media (min-width: ${(props) => props.useUnifiedLibrary ? `${SYS_BREAKPOINT_SM}` : `${props.theme.breakpoints.s}px`}) {
    margin: 0 28px;
  }
  @media (min-width: ${(props) => props.useUnifiedLibrary ? `${SYS_BREAKPOINT_MD}` : `${props.theme.breakpoints.m}px`}) {
    margin: 0 40px;
  }
  @media (min-width: ${(props) => props.useUnifiedLibrary ? `${SYS_BREAKPOINT_LG}` : `${props.theme.breakpoints.l}px`}) {
    margin: 0 60px;
  }
  @media (min-width: ${(props) => props.useUnifiedLibrary ? `${SYS_BREAKPOINT_XL}` : `${props.theme.breakpoints.xl}px`}) {
    margin: 0 96px;
  }
`;
