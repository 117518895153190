import React from 'react';
import styled from 'styled-components';
import AbstractSlider, { Item, SliderContainer, } from './AbstractSlider';
import { Icon, iconBackLarge, iconForwardLarge } from '../icon';
import { getI18NContext } from '@oneaudi/i18n-context';
export class ImageSlider extends AbstractSlider {
    constructor(props) {
        super(props);
        this.handleLeftAndRightKeys = this.handleLeftAndRightKeys.bind(this);
    }
    get slideIndex() {
        return super.slideIndex;
    }
    set slideIndex(slideIndex) {
        super.slideIndex = slideIndex;
        if (this.props.updatedSlide) {
            this.props.updatedSlide(slideIndex);
        }
    }
    get actionType() {
        return super.actionType;
    }
    set actionType(actionType) {
        super.actionType = actionType;
        if (this.props.updatedActionType) {
            this.props.updatedActionType(actionType);
        }
    }
    addEvents() {
        super.addEvents();
        window.addEventListener('keydown', this.handleLeftAndRightKeys);
    }
    removeEvents() {
        super.removeEvents();
        window.removeEventListener('keydown', this.handleLeftAndRightKeys);
    }
    handleLeftAndRightKeys(event) {
        if (!document.activeElement || !document.activeElement.contains(this.slider.current)) {
            return;
        }
        if (!AbstractSlider.isHidden(this.slider.current) &&
            ['ArrowLeft', 'ArrowRight'].indexOf(event.key) > -1) {
            event.preventDefault();
            if (event.key === 'ArrowLeft') {
                this.prevSlide();
            }
            else {
                this.nextSlide();
            }
        }
    }
    isCorrectSwipeDistance(absoluteSwipeDistance) {
        return absoluteSwipeDistance > 2 && absoluteSwipeDistance <= this.slideWidth;
    }
    focusSlide(index) {
        if (index === undefined || Number.isNaN(index)) {
            if (this.moveX > this.slideIndex * this.slideWidth &&
                this.slideIndex < this.slidesCount - 1) {
                this.slideIndex += 1;
            }
            else if (this.moveX < this.slideIndex * this.slideWidth && this.slideIndex > 0) {
                this.slideIndex -= 1;
            }
        }
        else {
            this.slideIndex = index;
        }
    }
    getTranslationFromContext(id) {
        var _a, _b;
        const { i18nState } = this.context;
        const { language } = i18nState;
        return (_b = (_a = i18nState.i18nData) === null || _a === void 0 ? void 0 : _a[language]) === null || _b === void 0 ? void 0 : _b[id];
    }
    handlePrevClick() {
        this.actionType = 'click';
        this.prevSlide();
    }
    handleNextClick() {
        this.actionType = 'click';
        this.nextSlide();
    }
    render() {
        const { children } = this.props;
        const items = React.Children.toArray(children);
        const ButtonPrevLabel = this.getTranslationFromContext('nemo.ui.sc.gridstage-slider-button-prev');
        const ButtonNextLabel = this.getTranslationFromContext('nemo.ui.sc.gridstage-slider-button-next');
        return (React.createElement(Wrapper, null,
            React.createElement(SliderContainer, { ref: this.slider, slideable: true }, items.map((item, index) => (React.createElement(FullWidthItem, { className: `item item-${index}`, key: `item${index}`, "data-index": index },
                React.createElement(React.Fragment, null, item))))),
            !this.props.disableButtonNavigation && this.slideIndex > 0 && (React.createElement(ButtonPrev, { "aria-label": ButtonPrevLabel, onClick: () => this.handlePrevClick() },
                React.createElement(Icon, { icon: iconBackLarge }))),
            !this.props.disableButtonNavigation && this.slideIndex < this.slidesCount - 1 && (React.createElement(ButtonNext, { "aria-label": ButtonNextLabel, onClick: () => this.handleNextClick() },
                React.createElement(Icon, { icon: iconForwardLarge })))));
    }
}
ImageSlider.contextType = getI18NContext();
const Wrapper = styled.div `
  display: flex;
  flex: 1 1 100%;
  overflow: hidden;
  width: 100%;
`;
export const FullWidthItem = styled(Item) `
  width: 100%;
`;
const Button = styled.button `
  position: absolute;
  top: calc(50% - 24px);
  z-index: 1;
  display: none;
  margin: 0;
  padding: 0;
  border: none;
  background-color: var(${(props) => props.theme.colors.base.grey[100]});
  cursor: pointer;

  & svg {
    display: block;
    color: var(${(props) => props.theme.colors.base.grey[0]});
  }

  @media (min-width: 768px) {
    display: block;
  }
`;
const ButtonPrev = styled(Button) `
  left: 0;
`;
const ButtonNext = styled(Button) `
  right: 0;
`;
export default ImageSlider;
