import React from 'react';
import {
  AppContextForThisInstance,
  type TileContentConfiguration,
  InitialViewTypeForTheTiles,
} from '../../@types/editor.d';

export interface TileContextState {
  viewType: InitialViewTypeForTheTiles;
  setViewType: (viewType: InitialViewTypeForTheTiles) => void;
  appContext: AppContextForThisInstance;
  contentConfiguration?: TileContentConfiguration;
}

export const TileContext = React.createContext<TileContextState>({
  setViewType: () => {},
  appContext: 'results',
  viewType: 'list',
});

export function isListView() {
  const context = React.useContext(TileContext);
  return context.viewType === 'list';
}

export function getAppContext() {
  const context = React.useContext(TileContext);
  return context.appContext;
}

export function getContentConfiguration() {
  const context = React.useContext(TileContext);
  return context.contentConfiguration;
}
