import * as React from 'react';
import { Button, Text } from '@audi/audi-ui-react-v2';
import { useI18n } from '@oneaudi/i18n-context';
import { ConsumptionTileItem } from './ConsumptionTileItem';
import { messages } from '../../i18n/messages';
import { useVehicle } from '../../hooks';
import styled from 'styled-components';
const ConsumptionTabContainer = styled.div `
  ${({ isPlp, theme }) => (isPlp ? '' : `margin-left: var(${theme.responsive.spacing.s})`)};
`;
export const ConsumptionTileElement = ({ vehicleId, order, displayedItems, hideLayerBtn, isPlp, }) => {
    var _a, _b, _c, _d;
    const { vehicle } = useVehicle(vehicleId);
    const linkLabel = useI18n(messages.consumptionTileElement.consumptionLink);
    const consumptionDisclaimer = useI18n(messages.consumptionTileElement.consumptionDisclaimer);
    const items = order.filter((item) => displayedItems.includes(item));
    const hasValues = ((_a = vehicle === null || vehicle === void 0 ? void 0 : vehicle.io) === null || _a === void 0 ? void 0 : _a.hasWltp) ||
        ((_b = vehicle === null || vehicle === void 0 ? void 0 : vehicle.io) === null || _b === void 0 ? void 0 : _b.hasNedc) ||
        ((_c = vehicle === null || vehicle === void 0 ? void 0 : vehicle.vlsEnergyProvision) === null || _c === void 0 ? void 0 : _c.hasWltp) ||
        ((_d = vehicle === null || vehicle === void 0 ? void 0 : vehicle.vlsEnergyProvision) === null || _d === void 0 ? void 0 : _d.hasNedc);
    if (!vehicle || !hasValues) {
        return null;
    }
    return (React.createElement(ConsumptionTabContainer, { isPlp: isPlp, "data-testid": "consumption-tile-container" },
        React.createElement("div", null, items.map((item) => (React.createElement(ConsumptionTileItem, { key: `${item}${vehicle.id}`, vehicle: vehicle, type: item, isPlp: isPlp })))),
        (vehicle === null || vehicle === void 0 ? void 0 : vehicle.noNedc) && (React.createElement(Text, { variant: "copy3", spaceStackEnd: "m" }, consumptionDisclaimer)),
        !hideLayerBtn && (React.createElement(Button, { variant: "text", size: "small", className: "js-modal-layer-link sc-js-eec-link", "data-layer-type": "ModalConsumptionEmissionLayerElement", "data-id": vehicle.id }, linkLabel))));
};
