import * as React from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { useServicesContext } from '../../context';
import { Icon, iconSystemInfoSmall } from '../icon';
import { FeatureAppLoader } from '@feature-hub/react';
import { Text, Divider, Table, TableBody, TableCell, TableRowHeadingCell, TableRow, Button, } from '@audi/audi-ui-react-v2';
import styled from 'styled-components';
export const FinancingAllInPricingElement = ({ vehicle, }) => {
    var _a, _b;
    const { getConfiguration } = useServicesContext();
    const scopesFromConfig = (_a = getConfiguration()) === null || _a === void 0 ? void 0 : _a.scopes;
    const hideAllInPricing = scopesFromConfig === null || scopesFromConfig === void 0 ? void 0 : scopesFromConfig.hidePrices;
    const layerManager = useServicesContext().getAdditionalService('gfa:layer-manager');
    const PRICE_TYPES = [
        'retail',
        'CUSTOM_PRICE_1',
        'CUSTOM_PRICE_2',
        'CUSTOM_PRICE_3',
        'CUSTOM_PRICE_4',
        'CUSTOM_TAXATION',
        'CUSTOM_TAXATION_2',
    ];
    const layerHeadline = useI18n({ id: 'nemo.ui.sc.details.finance.modal.headline' });
    const subLayerHeadline = useI18n({
        id: 'nemo.ui.sc.details.finance.modal.subheadline',
        defaultMessage: '',
    });
    const layerDisclaimerHeadline = useI18n({
        id: 'nemo.ui.sc.details.finance.modal.disclaimer.headline',
        defaultMessage: '',
    });
    const layerDisclaimer = useI18n({
        id: 'nemo.ui.sc.details.finance.modal.disclaimer.text',
        defaultMessage: '',
    });
    const showPricingDetailButton = !hideAllInPricing &&
        (vehicle === null || vehicle === void 0 ? void 0 : vehicle.typedPrices) &&
        (vehicle === null || vehicle === void 0 ? void 0 : vehicle.typedPrices.filter((price) => price.type !== 'retail' && PRICE_TYPES.includes(price.type)).length);
    if (!showPricingDetailButton) {
        return null;
    }
    const filteredTypedPrices = [...vehicle.typedPrices].filter((price) => PRICE_TYPES.includes(price.type));
    const carType = vehicle.type === 'N' ? 'new' : 'used';
    const extendedTypePrices = filteredTypedPrices.map((filteredTypedPrice) => {
        const extendedTypePrice = Object.assign({}, filteredTypedPrice);
        if (extendedTypePrice.type === 'retail') {
            extendedTypePrice.label = useI18n({
                id: 'nemo.ui.sc.details.finance.modal.RETAIL_PRICE',
                defaultMessage: 'Retail Price',
            });
            extendedTypePrice.footnote = `fn_price_stockcar_${carType}`;
        }
        else {
            extendedTypePrice.label = useI18n({
                id: `nemo.ui.sc.details.finance.modal.${extendedTypePrice.type}`,
                defaultMessage: extendedTypePrice.type,
            });
        }
        return extendedTypePrice;
    });
    const sortedTypedPrices = [...extendedTypePrices].sort((a, b) => {
        if (b.type === 'retail') {
            return -1;
        }
        if (a.type === 'retail') {
            return 1;
        }
        if (b.type.match('CUSTOM_PRICE') && a.type.match('CUSTOM_TAXATION')) {
            return -1;
        }
        if (a.type.match('CUSTOM_PRICE') && b.type.match('CUSTOM_TAXATION')) {
            return 1;
        }
        return a.type - b.type;
    });
    const config = useServicesContext().getConfiguration();
    const scStartPageUrl = (_b = config.urls) === null || _b === void 0 ? void 0 : _b.scStartPageLink;
    const layer = (React.createElement(React.Fragment, null,
        React.createElement(Text, { variant: "order2", as: "h2" }, layerHeadline),
        React.createElement(Text, { variant: "order3", as: "h3" }, subLayerHeadline),
        React.createElement(Table, { spaceStackStart: "l", spaceStackEnd: "xl" },
            React.createElement(TableBody, null, sortedTypedPrices.map((price) => (React.createElement(TableRow, { key: price.type },
                React.createElement(TableRowHeadingCell, null, price.label),
                React.createElement(TableCell, null, price.formatted)))))),
        layerDisclaimerHeadline.trim() !== '' && (React.createElement(React.Fragment, null,
            React.createElement(Divider, null),
            React.createElement(Text, { variant: "copy1", as: "p", weight: "bold" }, layerDisclaimerHeadline),
            React.createElement(Text, { variant: "copy1", as: "p" }, layerDisclaimer)))));
    const clickEvent = () => {
        layerManager === null || layerManager === void 0 ? void 0 : layerManager.openFocusLayer(() => (React.createElement(LayerContainer, { className: "financingAllInPriceLayer" },
            layer,
            React.createElement(FeatureAppLoader, { featureAppId: "financingAllInPriceLayer-footnotes", src: "https://fa-footnote-engine.cdn.prod.arcade.apps.one.audi/v2.4.8/fh/app.js", config: {
                    __type: 'aem-headless',
                    config: {
                        fields: {
                            apiUrls: [
                                `${scStartPageUrl}/commons/sc_footnotes/_jcr_content/content/sc_footnotes.json`,
                            ],
                            omitSideSpacing: true,
                            omitBottomSpacing: true,
                            uiTheme: 'light',
                            layerElementClassName: 'financingAllInPriceLayer',
                        },
                    },
                } }))), {}, {
            size: "A",
            userCloseable: true,
        });
    };
    return (React.createElement("div", null,
        React.createElement(Button, { variant: "text", onClick: clickEvent, icon: React.createElement(Icon, { icon: iconSystemInfoSmall }), "data-testid": "allInPrice" })));
};
const LayerContainer = styled.div ``;
