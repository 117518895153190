import { VehicleBasic, useServicesContext, generatePdpUrl } from '@oneaudi/vtp-shared';
import { getContentConfiguration } from './tileHook';
import { FeatureAppConfig } from '../FeatureHubAppDefinition';
import { useMemo } from 'react';

export const useVehicleDetailsPageUrl = (vehicle: VehicleBasic | undefined) => {
  const contentConfiguration = getContentConfiguration();
  const servicesContext = useServicesContext();

  return useMemo(() => {
    if (!vehicle) {
      return undefined;
    }
    const detailsPageUrlPattern = contentConfiguration?.urls?.detailsPageUrlPattern;
    if (!detailsPageUrlPattern) {
      return vehicle.entryUrl;
    }
    const featureAppConfig = servicesContext?.featureAppConfig as FeatureAppConfig;

    const seoUrl = generatePdpUrl(
      vehicle.modelYear,
      vehicle.model?.description || vehicle.symbolicCarline.description!,
      vehicle.id
    );

    return `${featureAppConfig.baseUrl || ''}${detailsPageUrlPattern?.replace(
      'SC_VEHICLE_ID',
      vehicle.id
    )}`.replace('SC_VEHICLE', seoUrl);
  }, [servicesContext, vehicle]);
};
